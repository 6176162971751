<template>
  <div class="animated fadeIn">
    <h6>Outstanding Amount: ${{ numberFormat(table.summary.outstandingAmount) }}</h6>
    <h6>Deposit: ${{ numberFormat(table.summary.deposit) }}</h6>
    <CardTable :title="title" :columns="table.columns" :rows="table.rows" :isLoading="table.isLoading"
               :pageSize="serverParams.pageSize" :pageNumber="serverParams.pageNumber" :totalPage="table.totalPage"
               :totalRecords="table.totalRecords" :pagination="false" @onPageChange="onPageChange"
               :stickyHeader="`${tableHeight(300)}px`">
      <template #filter>
        <SectionFilter :serverParams="serverParams" @onChange="getAll" @onExport="exportAll" />
      </template>
      <!--<template #custom-foot style="inset-block-end: 0; width: 100%;" v-if="!table.isLoading">
        <b-th class="text-center" colspan="5">Outstanding Amount</b-th>
        <b-th class="text-left">${{ numberFormat(table.summary.outstandingAmount) }}</b-th>
      </template>-->
    </CardTable>
  </div>
</template>

<style>
  tfoot,
  tfoot th,
  tfoot td {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    background: #fff;
    z-index: 4;
  }

    tfoot th:first-child {
      z-index: 5;
    }
</style>

<script>
  import { reportAccountStatements as columns } from "@/shared/columns";
  import { getDate, numberFormat, tableHeight } from "@/shared/utils";
  import SectionFilter from "./SectionFilter.vue";
  export default {
    components: {
      SectionFilter,
    },
    data: () => ({
      title: "Account Statements",
      serverParams: {
        pageNumber: 1,
        pageSize: 400,
        fromDate: null,
        toDate: getDate(),
      },
      table: {
        isLoading: false,
        columns: columns,
        rows: [],
        summary: {},
      },
    }),
    created() {
      const self = this;
      //self.getAll();
    },
    methods: {
      numberFormat,
      tableHeight,
      updateParams(newProps) {
        this.serverParams = Object.assign({}, this.serverParams, newProps);
      },
      onPageChange(params) {
        this.updateParams({ pageNumber: params });
        this.getAll();
      },
      getAll() {
        const self = this;
        if (!self.serverParams.account) {
          self.$message.error({
            zIndex: 10000,
            message: "Account no is required",
          });
          return;
        }
        self.table.isLoading = true;
        self.$store
          .dispatch("apis/get", {
            url: "/report/account-statements",
            params: self.serverParams,
          })
          .then((response) => {
            if (response.error) {
              self.$message.error({
                zIndex: 10000,
                message: response.message,
              });
            } else {
              self.table.rows = response.data.data;
              self.table.summary = response.data.additionalData;
            }
            self.table.isLoading = false;
          });
      },
      exportAll() {
        const self = this;
        if (!self.serverParams.account) {
          self.$message.error({
            zIndex: 10000,
            message: "Account no is required",
          });
          return;
        }
        self.$store
          .dispatch("apis/download", {
            url: "/report/account-statements/export",
            params: self.serverParams,
          })
          .then((response) => {
            console.log(response)
            if (response.error) {
              self.$message.error({
                zIndex: 10000,
                message: response.message,
              });
            } else {
              let fileURL = window.URL.createObjectURL(new Blob([response])),
                fileLink = document.createElement("a");

              fileLink.href = fileURL;
              fileLink.setAttribute("download", "SOA.xls");
              document.body.appendChild(fileLink);

              fileLink.click();
            }
          });
      },
    },
  };
</script>
